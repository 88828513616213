import React from "react"
import { Link } from "gatsby"

import Layout, { Section } from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <Section>
      <div className="container" style={{ textAlign: "center" }}>
        <h1>This page does not exist.</h1>
        <Link to="/">Home page</Link>
      </div>
    </Section>
  </Layout>
)

export default NotFoundPage
